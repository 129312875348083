// Generated by Framer (667f196)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {F1ar1crgt: {hover: true}};

const cycleOrder = ["F1ar1crgt"];

const variantClassNames = {F1ar1crgt: "framer-v-1gizy9d"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "F1ar1crgt", image: ecZWiFcCS, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "F1ar1crgt", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-r3VUv", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1gizy9d", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"F1ar1crgt"} ref={ref} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}} transition={transition} {...addPropertyOverrides({"F1ar1crgt-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", ...toResponsiveImage(ecZWiFcCS)}} className={"framer-3tix3y"} data-framer-name={"Loop Image"} layoutDependency={layoutDependency} layoutId={"L7QLOO1hR"} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-r3VUv [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-r3VUv * { box-sizing: border-box; }", ".framer-r3VUv .framer-1ln7h4u { display: block; }", ".framer-r3VUv .framer-1gizy9d { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 316px; justify-content: center; overflow: hidden; padding: 5px 5px 5px 5px; position: relative; width: 463px; will-change: transform; }", ".framer-r3VUv .framer-3tix3y { flex: none; height: 100%; overflow: hidden; position: relative; width: 100%; will-change: transform; }", ".framer-r3VUv .framer-v-1gizy9d .framer-1gizy9d { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-r3VUv .framer-1gizy9d { gap: 0px; } .framer-r3VUv .framer-1gizy9d > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-r3VUv .framer-1gizy9d > :first-child { margin-left: 0px; } .framer-r3VUv .framer-1gizy9d > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 316
 * @framerIntrinsicWidth 463
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"bHum2g6Th":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ecZWiFcCS":"image"}
 */
const FramerDGAoDaNtS: React.ComponentType<Props> = withCSS(Component, css, "framer-r3VUv") as typeof Component;
export default FramerDGAoDaNtS;

FramerDGAoDaNtS.displayName = "Loop Image Wrapper";

FramerDGAoDaNtS.defaultProps = {height: 316, width: 463};

addPropertyControls(FramerDGAoDaNtS, {ecZWiFcCS: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerDGAoDaNtS, [])